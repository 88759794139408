<template>
  <div class="referral-finished">
    <base-loader v-if="isDataLoading"></base-loader>
    <div class="referral-finished__content content">
      <div class="content-text">
        <div class="content-text__title">
          {{ $t("referrals_page.assistant.finish.title") }}
        </div>
        <div class="content-text__subtitle">
          {{ $t("referrals_page.assistant.finish.subtitle") }}
        </div>
      </div>
      <div class="content-action">
        <primary-colored-button @click="getReferralLink">
          {{ $t("referrals_page.assistant.finish.get_referral_link") }}
        </primary-colored-button>
      </div>
    </div>
    <div class="referral-finished__logo">
      <img src="@/assets/img/referral-program-finished.svg" alt="" />
    </div>
  </div>
</template>

<script>
import PrimaryColoredButton from "@/components/button/PrimaryColoredButton";
import BaseLoader from "@/components/base/BaseLoader";
import { ReferralsApi } from "@/office/referrals-api";
import { loadUserData } from "@/helpers/boot-helpers";

export default {
  name: "ReferralProgramFinish",
  components: { BaseLoader, PrimaryColoredButton },
  data() {
    return {
      isDataLoading: false,
    };
  },
  methods: {
    async getReferralLink() {
      this.isDataLoading = true;
      await ReferralsApi.approvePartner();
      await loadUserData();
      this.isDataLoading = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/_variables.scss";

.referral-finished {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 40px 56px 56px 40px;
  background-color: $gray3;
  border-radius: 24px;

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.content {
  display: grid;
  grid-auto-flow: row;
  row-gap: 3rem;
  height: 100%;
  justify-content: space-between;

  &-text {
    height: fit-content;
    display: grid;
    grid-auto-flow: row;
    row-gap: 1.5rem;

    &__title,
    &__subtitle {
      font-family: "Rubik", sans-serif;
      color: $mainBlack;
    }

    &__title {
      font-size: 1.25rem;
      line-height: 1.75rem;
      font-weight: 600;
    }

    &__subtitle {
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
}

@media screen and (max-width: 486px) {
  .referral-finished {
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    row-gap: 3.5rem;

    padding: 48px 24px;

    &__content {
      grid-row: 2;
    }
  }
  .content {
    row-gap: 2rem;
    &-text {
      row-gap: 1rem;
    }
  }
}
</style>
